/* Overriding Semantic styles to support our theming */
.main-container {
  background: url(/images/iphone-technology-iphone-6-plus-apple.jpg);
  background-size: cover;
  background-position: center;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.overlay {
  position: relative;
  z-index: 0;
}

.overlay::after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #1d5ab9;
  opacity: 0.6;
  /* !!! */
  z-index: -1;
}

.ui.primary.button,
.ui.primary.buttons .button {
  background-color: #1d5ab9;
}

.ui.form .field > label {
  color: #1d5ab9;
}

.field > .localedLabel {
  color: #1d5ab9;
  margin-bottom: 5px;
  font-weight: 700;
  font-size: 13px;
}
.ui.modal > .close {
  color: white !important;
}

.ui.modal > .content {
  padding: unset !important;
}

.nonMapSection {
  padding: 12px 20px;
  height: 178px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.mapViewModal {
  padding: 0px;
  height: 100%;
  width: 100% !important;
  margin: -14px 0 0 0 !important;
}

.selectFacilityHeader {
  font-size: 16px;
  display: flex;
  padding: 0 20px;
  height: 50px;
  align-items: center;
  justify-content: space-between;
}

.mapContainer,
.toastOpen > div {
  height: calc(100% - 380px) !important;
}

.mapContainer,
.toastClose > div {
  height: calc(100% - 230px) !important;
}

@keyframes mapViewToast {
  from {
    height: 0px;
  }
  to {
    height: 150px;
  }
}

.facilityDetailsToast {
  position: absolute;
  bottom: 0;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 150px;
  background-color: white;
  width: 100%;
  padding: 20px;
  animation: mapViewToast 0.5s;
  overflow-y: hidden;
}

.mapLocationStateSelect {
  display: flex;
  flex-direction: column;
}

.mapLocationStateSelect > .label-text,
.mapLocationStateSelect > .label-required {
  display: none !important;
}

/* specific styles for tablet resolution */
@media only screen and (min-width: 700px) {
  .nonMapSection {
    height: 138px;
  }

  .mapContainer,
  .toastClose > div {
    height: calc(100% - 190px) !important;
  }

  .mapContainer,
  .toastOpen > div {
    height: calc(100% - 340px) !important;
  }
}
